<template>
  <v-container class="fill-height messaging pa-0" fluid>
    <template>
      <v-layout column>
        <v-flex sm12 class="white" v-if="showSidebar"> </v-flex>
        <v-flex sm12 v-if="showWindow">
          มูลนิธิแม่ฟ้าหลวง ในพระบรมราชูปถัมภ์ (“มูลนิธิฯ”) จะเก็บ รวบรวม
          และใช้ข้อมูลที่ท่านกรอก ได้แก่ ชื่อ-นามสกุล อีเมล เบอร์โทรศัพท์
          เพื่อติดตามการนาหลักการทรงงานตามตาราแม่ฟ้าหลวงไปสู่การ
          ปฏิบัติและขยายผลได้อย่างประสิทธิภาพ มูลนิธิฯ
          รับประกันการเก็บรักษาข้อมูลส่วนบุคคลของท่านเพ่ือ
          จุดประสงค์ที่ระบุไว้เท่านั้นและจะรกั
          ษาความลับของข้อมูลตลอดระยะเวลาท่ีจาเป็นต้องใช้ขอ้ มูลดังกล่าวและ
          จะไม่เผยแพร่ข้อมูลของท่านให้บุคคลอื่นเว้นแต่จะเป็นกรณีจาเป็นเพื่อการจัดกิจกรรมให้แก่ท่านเท่าน้ัน
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
export default {}
</script>

<style></style>
